import { lazy } from 'react';
import { Index as Home } from './Index';

export const routes = {
  home: {
    path: '/',
    element: Home
  },
  salesOrders: {
    upload: {
      path: '/sales-orders/upload',
      element: lazy(() => import('features/sales-orders/Upload'))
    },
    import: {
      path: '/sales-orders/import',
      element: lazy(() => import('features/sales-orders/Import'))
    },
    confirm: {
      path: '/sales-orders/confirm',
      element: lazy(() => import('features/sales-orders/Confirm'))
    }
  }
};
