import { routes } from 'app/routes';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from 'utils/Loading';

export function Index() {
  const { isLoading } = useAuth0(),
    path = routes.salesOrders.upload.path;

  if (isLoading) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  return <Navigate to={path} />;
}
