import { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface IconProps extends FontAwesomeIconProps {
  icon: IconName;
}

export function Icon({ icon, ...props }: IconProps) {
  return <FontAwesomeIcon icon={['fal', icon]} {...props} />;
}
