import { ComponentType } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'boot';
import { useAuthentication } from 'boot/axios';
import { routes } from 'app/routes';
import { Layout } from 'app/Layout';
import 'styles/app.css';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from 'utils/Loading';

interface ProtectedRouteArgs {
  component: ComponentType<object>;
}

const ProtectedRoute = ({ component }: ProtectedRouteArgs) => {
  const Component = withAuthenticationRequired(component, { onRedirecting: () => <Loading /> });
  return <Component />;
};

function App() {
  useAuthentication();

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={routes.home.path} element={<ProtectedRoute component={routes.home.element} />} />
        <Route
          path={routes.salesOrders.upload.path}
          element={<ProtectedRoute component={routes.salesOrders.upload.element} />}
        />
        <Route
          path={routes.salesOrders.import.path}
          element={<ProtectedRoute component={routes.salesOrders.import.element} />}
        />
        <Route
          path={routes.salesOrders.confirm.path}
          element={<ProtectedRoute component={routes.salesOrders.confirm.element} />}
        />
      </Route>
    </Routes>
  );
}

export default App;
