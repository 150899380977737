import { ApiBase } from './api-base';
import { ImportSalesOrder, SalesOrderType } from './models/sales-order';
import {
  SpireInventoryItem,
  SpireCustomer,
  SalesOrderDetail,
} from './models/spire';

class SalesOrderService extends ApiBase {
  uploadData(): Promise<UploadDataResponse> {
    return this.get('sales-orders');
  }

  uploadFile({
    file,
    customerId,
  }: UploadFileArgs): Promise<UploadFileResponse> {
    var formData = new FormData();
    formData.append('file', file);
    formData.append('customer', customerId.toString());
    return this.post('sales-orders/upload', formData);
  }

  createOrder(args: CreateOrderArgs): Promise<CreateOrderResponse> {
    return this.post('sales-orders', args);
  }
}

export interface UploadFileArgs {
  file: File;
  customerId: number;
}

export interface UploadDataResponse {
  customers: SpireCustomer[];
}

export interface UploadFileResponse {
  inventory: SpireInventoryItem[];
  salesOrder: ImportSalesOrder;
}

export interface CreateOrderArgs {
  customerId: number;
  customerShipTo: string | null;
  shipToId: number | null;
  address: string | null;
  city: string | null;
  province: string | null;
  contactName: string | null;
  contactPhone: string | null;
  saveShipToMatch: boolean;
  purchaseOrderNumber: string | null;
  requiredDate: string | null;
  orderType: SalesOrderType;
  originalDocumentUrl: string | null;
  previewPublicId: string | null;
  fileContents: string | null;
  filename: string | null;
  items: CreateOrderItem[];
}

interface CreateOrderItem {
  inventoryId: number;
  orderQuantity: number;
  unitPrice: number;
  customerSku: string | null;
  customerDescription: string | null;
  unitOfMeasure: string | null;
  comment: string | null;
  saveMatch: boolean;
}

export interface CreateOrderResponse {
  spireOrderNumber: string | null;
  order: SalesOrderDetail;
}

export const salesOrderApi = new SalesOrderService();
