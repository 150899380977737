import {
  createSlice,
  PayloadAction,
  AsyncThunk,
  createAsyncThunk,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { ImportSalesOrder, SalesOrderType } from 'api/models/sales-order';
import {
  SpireCustomer,
  SpireInventoryItem,
  SalesOrderDetail,
} from 'api/models/spire';
import {
  salesOrderApi,
  UploadDataResponse,
  UploadFileArgs,
  UploadFileResponse,
  CreateOrderArgs,
  CreateOrderResponse,
} from 'api/sales-order-service';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';

export const getUploadData: AsyncThunk<
  UploadDataResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'sales-order-import/getUploadData',
  async (_, { rejectWithValue }) => {
    try {
      return await salesOrderApi.uploadData();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const uploadFile: AsyncThunk<
  UploadFileResponse,
  UploadFileArgs,
  { state: RootState }
> = createAsyncThunk(
  'sales-order-import/uploadFile',
  async (args, { rejectWithValue }) => {
    try {
      return await salesOrderApi.uploadFile(args);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createOrder: AsyncThunk<
  CreateOrderResponse,
  CreateOrderArgs,
  { state: RootState }
> = createAsyncThunk(
  'sales-order-import/createOrder',
  async (args, { rejectWithValue }) => {
    try {
      return await salesOrderApi.createOrder(args);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface ImportState {
  customers: SpireCustomer[];
  customerId: number | null;
  shipToId: number | null;
  address: string | null;
  city: string | null;
  province: string | null;
  contactName: string | null;
  contactPhone: string | null;
  invoiceNumber: string | null;
  inventory: SpireInventoryItem[];
  importOrder: ImportSalesOrder | null;
  salesOrder: SalesOrderDetail | null;
  spireOrderNumber: string | null;
  error: ProblemDetails | null;
  isLoading: boolean;
  isUploading: boolean;
}

const initialState: ImportState = {
  customers: [],
  customerId: null,
  shipToId: null,
  address: null,
  city: null,
  province: null,
  contactName: null,
  contactPhone: null,
  invoiceNumber: null,
  inventory: [],
  importOrder: null,
  salesOrder: null,
  spireOrderNumber: null,
  error: null,
  isLoading: false,
  isUploading: false,
};

const getImportDataPending = createAction(getUploadData.pending.type),
  getImportDataFulfilled = createAction<UploadDataResponse>(
    getUploadData.fulfilled.type
  ),
  getImportDataRejected = createAction<ProblemDetails>(
    getUploadData.rejected.type
  ),
  uploadFilePending = createAction(uploadFile.pending.type),
  uploadFileFulfilled = createAction<UploadFileResponse>(
    uploadFile.fulfilled.type
  ),
  uploadFileRejected = createAction<ProblemDetails>(uploadFile.rejected.type),
  createOrderPending = createAction(createOrder.pending.type),
  createOrderFulfilled = createAction<CreateOrderResponse>(
    createOrder.fulfilled.type
  ),
  createOrderRejected = createAction<ProblemDetails>(createOrder.rejected.type);

interface SetItemValueArgs<T> {
  itemId: number;
  value: T;
}

export const salesOrderImportSlice = createSlice({
  name: 'sales-order-import',
  initialState,
  reducers: {
    setError(state, { payload }: PayloadAction<ProblemDetails | null>) {
      state.error = payload;
    },
    setCustomerId(state, { payload }: PayloadAction<number | null>) {
      state.customerId = payload;
    },
    setShipToId(state, { payload }: PayloadAction<number | null>) {
      state.shipToId = payload;
    },
    setAddress(state, { payload }: PayloadAction<string | null>) {
      state.address = payload;
    },
    setCity(state, { payload }: PayloadAction<string | null>) {
      state.city = payload;
    },
    setProvince(state, { payload }: PayloadAction<string | null>) {
      state.province = payload;
    },
    setContactName(state, { payload }: PayloadAction<string | null>) {
      state.contactName = payload;
    },
    setContactPhone(state, { payload }: PayloadAction<string | null>) {
      state.contactPhone = payload;
    },
    setInvoiceNumber(state, { payload }: PayloadAction<string | null>) {
      state.invoiceNumber = payload;
    },
    setPurchaseOrderNumber(state, { payload }: PayloadAction<string | null>) {
      if (state.importOrder) {
        state.importOrder.purchaseOrderNumber = payload;
      }
    },
    setOrderType(state, { payload }: PayloadAction<SalesOrderType>) {
      if (state.importOrder) {
        state.importOrder.orderType = payload;
      }
    },
    setOrderDate(state, { payload }: PayloadAction<Date | null>) {
      if (state.importOrder) {
        if (!payload) {
          state.importOrder.orderDate = null;
        } else {
          const orderDate = moment(payload).format('YYYY-MM-DD');
          state.importOrder.orderDate = orderDate;
        }
      }
    },
    setRequiredDate(state, { payload }: PayloadAction<Date | null>) {
      if (state.importOrder) {
        if (!payload) {
          state.importOrder.requiredDate = null;
        } else {
          const requiredDate = moment(payload).format('YYYY-MM-DD');
          state.importOrder.requiredDate = requiredDate;
        }
      }
    },
    setItemInventoryId(
      state,
      { payload }: PayloadAction<SetItemValueArgs<number | null>>
    ) {
      if (state.importOrder) {
        const { itemId, value } = payload,
          salesOrder = { ...state.importOrder },
          items = salesOrder.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === itemId);

        if (item) {
          item.inventoryId = value;
        }

        salesOrder.items = items;
        state.importOrder = salesOrder;
      }
    },
    setItemOrderQuantity(
      state,
      { payload }: PayloadAction<SetItemValueArgs<number>>
    ) {
      if (state.importOrder) {
        const { itemId, value } = payload,
          salesOrder = { ...state.importOrder },
          items = salesOrder.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === itemId);

        if (item) {
          item.orderQuantity = value;
        }

        salesOrder.items = items;
        state.importOrder = salesOrder;
      }
    },
    setItemUnitPrice(
      state,
      { payload }: PayloadAction<SetItemValueArgs<number>>
    ) {
      if (state.importOrder) {
        const { itemId, value } = payload,
          salesOrder = { ...state.importOrder },
          items = salesOrder.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === itemId);

        if (item) {
          item.unitPrice = value;
        }

        salesOrder.items = items;
        state.importOrder = salesOrder;
      }
    },
    setItemUnitOfMeasure(
      state,
      { payload }: PayloadAction<SetItemValueArgs<string | null>>
    ) {
      if (state.importOrder) {
        const { itemId, value } = payload,
          salesOrder = { ...state.importOrder },
          items = salesOrder.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === itemId);

        if (item) {
          item.unitOfMeasure = value;
        }

        salesOrder.items = items;
        state.importOrder = salesOrder;
      }
    },
    setItemUpc(
      state,
      { payload }: PayloadAction<SetItemValueArgs<string | null>>
    ) {
      if (state.importOrder) {
        const { itemId, value } = payload,
          salesOrder = { ...state.importOrder },
          items = salesOrder.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === itemId);

        if (item) {
          item.upc = value;
        }

        salesOrder.items = items;
        state.importOrder = salesOrder;
      }
    },
    setItemComment(
      state,
      { payload }: PayloadAction<SetItemValueArgs<string | null>>
    ) {
      if (state.importOrder) {
        const { itemId, value } = payload,
          salesOrder = { ...state.importOrder },
          items = salesOrder.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === itemId);

        if (item) {
          item.comment = value;
        }

        salesOrder.items = items;
        state.importOrder = salesOrder;
      }
    },
    setItemCustomerDescription(
      state,
      { payload }: PayloadAction<SetItemValueArgs<string>>
    ) {
      if (state.importOrder) {
        const { itemId, value } = payload,
          salesOrder = { ...state.importOrder },
          items = salesOrder.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === itemId);

        if (item) {
          item.customerDescription = value;
        }

        salesOrder.items = items;
        state.importOrder = salesOrder;
      }
    },
    setItemSaveMatch(
      state,
      { payload }: PayloadAction<SetItemValueArgs<boolean>>
    ) {
      if (state.importOrder) {
        const { itemId, value } = payload,
          salesOrder = { ...state.importOrder },
          items = salesOrder.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === itemId);

        if (item) {
          item.saveMatch = value;
        }

        salesOrder.items = items;
        state.importOrder = salesOrder;
      }
    },
    setSaveShipToMatch(state, { payload }: PayloadAction<boolean>) {
      if (state.importOrder) {
        const salesOrder = { ...state.importOrder };
        salesOrder.saveShipToMatch = payload;
        state.importOrder = salesOrder;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getImportDataPending, (state) => {
        state.isUploading = false;
        state.isLoading = true;
        state.salesOrder = null;
        state.importOrder = null;
        state.customerId = null;
      })
      .addCase(getImportDataFulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { customers } = payload;
        state.customers = customers;
      })
      .addCase(getImportDataRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(uploadFilePending, (state) => {
        state.isUploading = true;
        state.error = null;
      })
      .addCase(uploadFileFulfilled, (state, { payload }) => {
        state.isUploading = false;
        const { inventory, salesOrder } = payload;
        state.inventory = inventory;
        state.importOrder = salesOrder;

        if (salesOrder.requiredDate) {
          const m = moment(salesOrder.requiredDate);
          if (m.isValid()) {
            state.importOrder.requiredDate = m.format('YYYY-MM-DD');
          }
        }

        const {
          customer,
          address,
          city,
          province,
          contactName,
          contactPhone,
          invoiceNumber,
        } = salesOrder;
        state.shipToId = salesOrder.shipToId;
        if (!state.shipToId && customer?.shipTos.length) {
          state.shipToId = customer.shipTos[0].shipToId;
        }

        state.address = address;
        state.city = city;
        state.province = province;
        state.contactName = contactName;
        state.contactPhone = contactPhone;
        state.invoiceNumber = invoiceNumber;
      })
      .addCase(uploadFileRejected, (state, { payload }) => {
        state.isUploading = false;
        state.error = payload;
      })
      .addCase(createOrderPending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrderFulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.salesOrder = payload.order;
        state.spireOrderNumber = payload.spireOrderNumber;
      })
      .addCase(createOrderRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }),
});

export const {
  setError,
  setPurchaseOrderNumber,
  setOrderType,
  setCustomerId,
  setShipToId,
  setAddress,
  setCity,
  setProvince,
  setContactName,
  setContactPhone,
  setInvoiceNumber,
  setOrderDate,
  setRequiredDate,
  setItemInventoryId,
  setItemOrderQuantity,
  setItemUnitPrice,
  setItemUnitOfMeasure,
  setItemUpc,
  setItemCustomerDescription,
  setItemComment,
  setItemSaveMatch,
  setSaveShipToMatch,
} = salesOrderImportSlice.actions;

export const selectIsLoading = (state: RootState) =>
  state.salesOrderImport.isLoading;
export const selectIsUploading = (state: RootState) =>
  state.salesOrderImport.isUploading;
export const selectError = (state: RootState) => state.salesOrderImport.error;
export const selectCustomers = (state: RootState) =>
  state.salesOrderImport.customers;
export const selectCustomerId = (state: RootState) =>
  state.salesOrderImport.customerId;
export const selectShipToId = (state: RootState) =>
  state.salesOrderImport.shipToId;
export const selectAddress = (state: RootState) =>
  state.salesOrderImport.address;
export const selectCity = (state: RootState) => state.salesOrderImport.city;
export const selectProvince = (state: RootState) =>
  state.salesOrderImport.province;
export const selectContactName = (state: RootState) =>
  state.salesOrderImport.contactName;
export const selectContactPhone = (state: RootState) =>
  state.salesOrderImport.contactPhone;
export const selectInvoiceNumber = (state: RootState) =>
  state.salesOrderImport.invoiceNumber;
export const selectInventory = (state: RootState) =>
  state.salesOrderImport.inventory;
export const selectImportOrder = (state: RootState) =>
  state.salesOrderImport.importOrder;
export const selectSalesOrder = (state: RootState) =>
  state.salesOrderImport.salesOrder;
export const selectSpireOrderNumber = (state: RootState) =>
  state.salesOrderImport.spireOrderNumber;

export const selectCustomer = createSelector(
  selectCustomers,
  selectCustomerId,
  (customers, customerId) =>
    customers.find((c) => c.customerId === customerId) || null
);

export const selectShipTo = createSelector(
  selectCustomer,
  selectShipToId,
  (customer, shipToId) =>
    customer?.shipTos.find((c) => c.shipToId === shipToId) || null
);

export default salesOrderImportSlice.reducer;
