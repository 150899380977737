import { useEffect } from 'react';
import Axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const baseURL = process.env.REACT_APP_API_URL;

console.debug('API baseURL', baseURL);

const axios = Axios.create({
  baseURL
});

export function useAuthentication() {
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();

  useEffect(() => {
    const action = async () => {
      if(isAuthenticated) {
        const token = await getAccessTokenSilently();
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        delete axios.defaults.headers.common['Authorization'];
      }
    }

    action();
  }, [getAccessTokenSilently, isAuthenticated]);
}


export default axios;
